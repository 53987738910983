import React from "react";
import Container from "../container/container";
import styles from "./footer.module.css";

export default () => (
  <footer className={styles.footer}>
    <div className="wrapper">
      <Container>
        <div className={styles.footer__container}>
          <nav>
            <a href="/mobile-eula" title="Sukar EULA">
              Mobile EULA
            </a>
            <a href="/privacy" title="Sukar privacy statement">
              Privacy
            </a>
          </nav>
          <span>&copy; 2020 Appthena - All rights reserved</span>
        </div>
      </Container>
    </div>
  </footer>
);
