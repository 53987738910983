/* eslint-disable react/prop-types */
import React from "react";
import "../base.css";
import Container from "../container/container";
import Header from "../header/header";
import Footer from "../footer/footer";

class Template extends React.PureComponent {
  render() {
    const { children } = this.props;

    return (
      <>
        <Header />
        <div className="wrapper">
          <Container>{children}</Container>
        </div>
        <Footer />
      </>
    );
  }
}

export default Template;
